import { Controller } from "@hotwired/stimulus"

import { computePosition, offset, flip, shift } from '@floating-ui/dom';

export default class extends Controller {
  static targets = ["origin", "floatable"]

  static values = {
    placement: {
      type: String,
      default: 'bottom-center'
    },
    offset: {
      type: Number,
      default: 14
    },
    padding: {
      type: Number,
      default: 14
    }
  }

  floatableTargetConnected() {
    this.arrange()
  }

  arrange() {
    if (this.hasFloatableTarget) {
      computePosition(this.originTarget, this.floatableTarget, {
        placement: this.placementValue,
        middleware: [
          offset(this.offsetValue),
          flip(),
          shift({ padding: this.paddingValue }),
        ]
      }).then(({x, y }) => {
        Object.assign(this.floatableTarget.style, {
          left: `${x}px`,
          top: `${y}px`,
        })
      })
    }
  }
}

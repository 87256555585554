import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    event: String,
    options: Object,
  }

  register() {
    if (typeof plausible === "function") {
      plausible(this.eventValue, this.optionsValue)
    }
  }
}

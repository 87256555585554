
        import * as module0 from './controllers/data_collection_controller.js';import * as module1 from './controllers/float_controller.js';import * as module2 from './controllers/local_clock_controller.js';import * as module3 from './controllers/toggle_controller.js';import * as module4 from './controllers/track_controller.js'
        const modules = {
            "./controllers/data_collection_controller.js": module0,
            "./controllers/float_controller.js": module1,
            "./controllers/local_clock_controller.js": module2,
            "./controllers/toggle_controller.js": module3,
            "./controllers/track_controller.js": module4,
        };
        export default modules;
      
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["custom"]
  static targets = ["togglable"]
  static values = {
    addOnDisconnect: {
      type: Boolean,
      default: false
    },
    removeOnDisconnect: {
      type: Boolean,
      default: false
    }
  }

  disconnect() {
    if (this.addOnDisconnectValue) {
      this.add()
    }
    if (this.removeOnDisconnectValue) {
      this.remove()
    }
  }

  add() {
    if (this.hasTogglableTarget) {
      this.togglableTarget.classList.add(this.customClass)
    } else {
      this.element.classList.add(this.customClass)
    }
  }

  remove() {
    if (this.hasTogglableTarget) {
      this.togglableTarget.classList.remove(this.customClass)
    } else {
      this.element.classList.remove(this.customClass)
    }
  }
}
